import React from 'react'
// import { Link } from 'gatsby'

import Layout from '../components/layout'

const LegalPage = () => (
  <Layout>
    <div className="container">
      <h2>Пользовательское соглашение и политика конфиденциальности</h2>
      <p>
        <strong>1. Определение терминов</strong>
        <br/>
        1.1. В настоящем Пользовательском Соглашении используются следующие термины:
        <br/>
        1.1.1. «Администрация сайта LoyaltyPro.ru (далее – Администрация сайта или Оператор)» – уполномоченные сотрудники на управление сайтом, действующие от имени LoyaltyPro.ru, которые организуют и (или) осуществляет обработку персональных данных, а также определяет цели обработки персональных данных, состав персональных данных, подлежащих обработке, действия (операции), совершаемые с персональными данными.
        <br/>
        1.1.2. «Персональные данные» - любая информация, относящаяся к прямо или косвенно определенному или определяемому физическому лицу (субъекту персональных данных).
        <br/>
        1.1.3. «Обработка персональных данных» - любое действие (операция) или совокупность действий (операций), совершаемых с использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных.
        <br/>
        1.1.4. «Конфиденциальность персональных данных» - обязательное для соблюдения Оператором или иным получившим доступ к персональным данным лицом требование не допускать их распространения без согласия субъекта персональных данных или наличия иного законного основания.
        <br/>
        1.1.5. «Пользователь сайта LoyaltyPro.ru (далее ‑ Пользователь)» – лицо, имеющее доступ к Сайту, посредством сети Интернет и использующее Сайт.
        <br/>
        1.1.6. «Cookie» — небольшой фрагмент данных, отправленный веб-сервером и хранимый на компьютере пользователя, который веб-клиент или веб-браузер каждый раз пересылает веб-серверу в HTTP-запросе при попытке открыть страницу соответствующего сайта.
        <br/>
        1.1.7. «IP-адрес» — уникальный сетевой адрес узла в компьютерной сети, построенной по протоколу IP.
      </p>
      <p>
        <strong>2. Общие положения.</strong>
        <br/>
        2.1. Предметом данного Соглашения является сохранение конфиденциальности персональных данных Пользователя сайта LoyaltyPro.ru.
        <br/>
        2.2. Использование Пользователем сайта LoyaltyPro.ru означает согласие с настоящим Пользовательским Соглашением и условиями обработки персональных данных Пользователя.
        <br/>
        2.3. В случае несогласия с условиями Пользовательского Соглашения и Политики Конфиденциальности Пользователь должен прекратить использование сайта.
        <br/>
        2.4. Настоящая Политика применима только к сайту LoyaltyPro.ru и его поддоменам. LoyaltyPro.ru не контролирует и не несет ответственность за обработку информации сайтами третьих лиц, на которые пользователь может перейти по ссылкам, доступным на сайтах LoyaltyPro.ru.
        <br/>
        2.5.Администрация сайта не проверяет достоверность персональных данных, предоставляемых Пользователем сайта.
      </p>
      <p>
        <strong>3. Обязанности пользователя.</strong>
        <br/>
        Пользователь гарантирует: предоставленная им информация является точной и достоверной; при предоставлении информации не нарушается действующее законодательство Российской Федерации, не страдают законные права и интересы третьих лиц; вся предоставленная информация заполнена гражданином в отношении себя лично.
      </p>
      <p>
        <strong>4. Политика конфиденциальности.</strong>
        <br/>
        4.1. Состав персональных данных.
        <br/>
        LoyaltyPro.ru может собирать следующие персональные данные: адрес электронной почты и общедоступные данные, в том числе IP, информация о регионе пользователя, характеристиках электронного устройства и браузера.
        <br/>
        4.2. Цели обработки персональных данных.
      </p>
      <ul>
        LoyaltyPro.ru осуществляет обработку, в том числе сбор и хранение только той персональной информации, которая необходима для достижения целей, указанных в данном Соглашении. LoyaltyPro.ru вправе использовать персональные данные в следующих целях:

        <li>проведения маркетинговых, статистических и иных исследований на основе обезличенных данных;</li>
        <li>связи с пользователем, в том числе осуществления клиентской поддержки, консультирования по выбору товаров и/или услуг, обработки запросов и заявок;</li>
        <li>обеспечения пользователю доступа к сервисам сайта, в том числе опросникам и функционалу вопрос-ответ;</li>
        <li>анализа аудитории для улучшения функционала сайта.</li>
      </ul>
      <p>
        <strong>4.3. Защита персональных данных.</strong>
        <br/>
        LoyaltyPro.ru осуществляет хранение персональной информации и обеспечивает ее охрану от несанкционированного доступа и распространения.
        <br/>
        4.4. Изменение персональных данных
        <br/>
        Пользователь вправе требовать изменения или удаления своих персональных данных, в случае, если персональные данные являются неполными, устаревшими, неточными, незаконно полученными или не являются необходимыми для заявленной цели обработки.
        <br/>
        4.5. Хранение персональных данных.
        <br/>
        LoyaltyPro.ru не хранит ваши персональные данные дольше, чем необходимо для целей их сбора, или чем требуется по действующему законодательству.
        <br/>
        4.6. Сбор общедоступных данных.
        <br/>
        LoyaltyPro.ru может осуществлять сбор общедоступных данных пользователя (таких как IP-адрес, сведения об устройстве и т.д.) с помощью файлов cookie, журналов истории доступа и web-счетчиков. Если пользователь не согласен с этими условиями, он должен немедленно покинуть сайт.
        <br/>
        LoyaltyPro.ru может использовать услуги третьих сторон для мониторинга трафика, статистических исследований, рекламы и проведения других операций на сайтах. С разрешения LoyaltyPro.ru эти третьи стороны могут использовать файлы cookie, журналы истории доступа, web-счетчики и другие технологии мониторинга для компиляции анонимной агрегированной статистики по посетителям сайтов.
        <br/>
        4.7. Передача персональных данных третьим лицам.
        <br/>
        LoyaltyPro.ru обязуется не передавать персональные данные третьим сторонам для маркетинговых целей без разрешения пользователя.
        <br/>
        Администрация сайта может передавать персональные данные уполномоченным агентствам или подрядчикам для достижения обозначенных в данном Соглашении целей.
        <br/>
        Пользователь соглашается с тем, что Администрация сайта вправе передавать персональные данные третьим лицам, в частности, курьерским службам, организациями почтовой связи, операторам электросвязи, исключительно в обозначенных целях.
        <br/>
        Персональные данные Пользователя могут быть переданы уполномоченным органам государственной власти Российской Федерации только по основаниям и в порядке, установленным законодательством Российской Федерации.
      </p>
      <p>
        <strong>5. Соглашение.</strong>
        <br/>
        Действующая редакция Соглашения находится по адресу: LoyaltyPro.ru/policy/. LoyaltyPro.ru вправе в любое время в одностороннем порядке изменять условия настоящего Соглашения. При несогласии пользователя с внесенными изменениями он обязан отказаться от доступа к сайту, прекратить использование материалов и сервисов сайта, а также отправить запрос на удаление своих персональных данных.
      </p>
      <p>
        <strong>6. Почта для обратной связи: mail@LoyaltyPro.ru</strong>
      </p>
    </div>
  </Layout>
)

export default LegalPage
